import request from '@/plugins/axios.config'

export function getDiseaseList ({ pageNo, limit, title, explanation, suggest}) {
  return request({
    url: '/disease/page/list',
    data: {
      pageNo, limit,
      entity: {
        title, explanation, suggest
      }
    }
  })
}

export function deleteDiseaseExl ({ id}) {
  return request({
    url: `/disease/delete/${id}`,
    type: 'delete'
  })

}
export function saveDiseaseInfo (data) {
  return request({
    url: '/disease/save/info',
    data: data
  })
}
